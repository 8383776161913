.react-checkbox-tree {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    color: #000000;

    max-height: 200px;
    overflow: auto;
}


.react-checkbox-tree ol li.rct-node-parent {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.react-checkbox-tree ol li.rct-node-parent span.rct-text {
    display: flex;
    flex: 1;
    gap: 4px;
}

.react-checkbox-tree ol li.rct-node-parent span.rct-text button {
    padding: 0;
}

.react-checkbox-tree ol li.rct-node-parent span.rct-text label {
    display: flex;
    align-items: center;
    gap: 4px;
}

.react-checkbox-tree ol li.rct-node-parent span.rct-text label:hover {
    border-radius: 4px;
    background-color: transparent;
    color: #186deb;
}

.react-checkbox-tree ol li.rct-node-parent span.rct-text label span.rct-checkbox {
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

label span.rct-checkbox svg {
    height: 16px;
    width: 16px;
}

label span.rct-title {
    padding: 0;
}

.rct-node-icon {
    color: transparent;
    padding: 0;
}

.rct-checkbox rect{
    width: 100%;
}


span.rct-collapse {
    padding: 0;
}
