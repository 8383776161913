:root {
    --primary: #6da4d3;
}

* {
    box-sizing: border-box;
}

body {
    background-color: #f6f6f6;
    font-family: 'Space Grotesk', sans-serif;
    margin: 0;
    overflow-x: hidden;
    position: relative;
    -webkit-font-smoothing: antialiased !important;
    box-sizing: border-box;
}

button {
    cursor: pointer;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.input-error:enabled {
    border-color: #f89b9b !important;
}

h1,
h2,
h3,
h4,
h5,
span,
p {
    margin: 0;
}

.input-error:enabled:focus,
.input-error:enabled:focus-within,
.input-error:enabled:hover {
    border-color: #f03738 !important;
}

.input-success:enabled {
    border-color: #9de09d !important;
}

.input-success:enabled:focus,
.input-success:enabled:focus-within,
.input-success:enabled:hover {
    border-color: #3cc13b !important;
}

.input-warning:enabled {
    border-color: #f9dd8d !important;
}

.input-warning:enabled:focus,
.input-warning:enabled:focus-within,
.input-warning:enabled:hover {
    border-color: #f3bb1c !important;
}

.Toastify__toast-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.Toastify__toast-icon {
    display: none !important;
}

.Toastify__close-button {
    align-self: center;
    color: #7b7b7b;
}

@media screen and (max-width: 768px) {
    .w-full {
        width: 100% !important;
    }
}

::-webkit-scrollbar {
    height: 20px;
    width: 24px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-track-piece {
    height: 4px;
}

::-webkit-scrollbar-thumb {
    background-color: #d7d7d7;
    border-radius: 24px;
    border: 8px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a9a9a9;
}
